<template>
  <v-app>
    <AppFramework>
      <template v-slot:innerCard>
        <router-view :loading="loading"
        ></router-view>
      </template>
    </AppFramework>
  </v-app>
</template>

<script>
import AppFramework from "./components/AppFramework";

export default {
  name: 'App',
  components: {AppFramework},
  computed:{
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    loadData(){
      if(this.$store.state.jwt){
        this.$store.dispatch('getPersonDetails');
      }
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
