<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <v-card class="ma-2">
        <v-card-text>
          <MultiVimeoPlayer :videos="videos" @back="back()" @ended="ended()"></MultiVimeoPlayer>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MultiVimeoPlayer from "../components/MultiVimeoPlayer";

export default {
  components: {
    MultiVimeoPlayer
  },
  data() {
    return {
      videos2: [
        {
          title: "An introduction to nursing",
          short_title: "Nursing",
          url: 'https://vimeo.com/560852034'
        },
        {
          title: "An introduction to nursing",
          short_title: "Engineering",
          url: 'https://vimeo.com/560851918'
        },
        {
          title: "An introduction to Industrial Psychology",
          short_title: "IO Psychology",
          url: 'https://vimeo.com/560851852'
        },
        {
          title: "Arts and Culture at the NWU",
          short_title: "Arts & Culture",
          url: 'https://vimeo.com/560851757'
        }
      ]
    }
  },
  computed: {
    videos() {
      return this.$store.state.videoTour.videoData;
    },
  },
  methods:{
    back(){
      this.$router.replace({ name: 'InterestsAndCourseGroups' });
    },
    ended(){
      this.$router.push({ name: 'Complete' });
    }
  }
}
</script>

<style scoped>

</style>