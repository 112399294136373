<template>
  <div :id="id"></div>
</template>

<script>
  export default {
    name: "WrappedDiv",
    props:{
      id : {required: true}
    },
    mounted(){
      this.$emit('loaded',this.id);
    }
  }
</script>

<style scoped>

</style>