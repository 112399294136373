<template>
  <v-card :style="'width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <!-- TITLE -->
        <v-col cols="12">
          <h2>
            We want to get to know you
          </h2>
        </v-col>

        <v-col cols="12">
          <p>
            You have us at a disadvantage – you know about us, but we haven’t met you yet. Introduce yourself by
            completing the following fields.
            We look forward to getting to know you better. We respect your privacy and is committed to protecting it.
            We’ll just use your details to share information that’s of interest to you.
          </p>
        </v-col>

        <!-- DATA -->
        <v-col cols="12">

          <v-form ref="profileForm"
                  lazy-validation
          >
            <!-- Name -->
            <v-text-field label="Name"
                          v-model="person.name"
                          outlined
                          :rules="[(v) => !!v || 'Required']"
            ></v-text-field>

            <!-- EMAIL -->
            <v-text-field label="Email"
                          v-model="person.email"
                          type="email"
                          outlined
            ></v-text-field>

            <!-- STUDENT NUMBER -->
            <v-text-field label="Student number"
                          v-model="person.usnumber"
                          type="tel"
                          outlined
            ></v-text-field>

            <!-- ID NUMBER -->
            <v-text-field label="ID Number"
                          type="tel"
                          v-model="person.idnumber"
                          outlined
            ></v-text-field>

            <v-select label="Gender"
                      outlined
                      v-model="person.gender"
                      :items="data.genders"
                      item-text="name"
                      item-value="gender"
            >
            </v-select>

            <!-- GRADE -->
            <v-autocomplete label="Grade"
                            :items="data.grades"
                            item-text="description"
                            return-object
                            v-model="person.grades"
                            outlined
            ></v-autocomplete>
          </v-form>
        </v-col>

        <!-- BUTTONS -->
        <v-col cols class="d-flex flex-row">
          <v-btn
              color="secondary"
              :disabled="loading"
              @click="logout()"
          >
            Logout
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="primary"
                 @click="updatePersonProfile()"
                 :loading="loading"
          >
            Proceed
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Register",
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
  data() {
    return {
      person: {
        name: '',
        email: '',
        usnumber: '',
        idnumber: '',
        grades: null,
        gender: 0
      },
      data: {
        grades: [],
        genders: []
      },
      jwt: null,
    }
  },
  methods: {
    getPersonProfile() {
      this.$store.dispatch('getPersonDetails').then(() => {
        this.person = this.$store.state.person ?? this.person;
        this.data = this.$store.state.data ?? this.data;

        if (this.person.grades && this.person.grades.length > 0) {
          this.person.grades = this.person.grades[0];
        }

        this.data.genders = [
          {gender: 0, name: 'Male'},
          {gender: 1, name: 'Female'},
        ];
        this.$forceUpdate();
      });
    },
    updatePersonProfile() {
      if (!this.$refs['profileForm'].validate()) return;

      let payload = {
        person: this.person,
        jwt: this.jwt
      };

      this.$store.dispatch('updatePersonDetails', payload).then(() => {
        this.$router.push({name: 'InterestsAndCourseGroups'})
      });
    },
    logout() {
      this.$store.dispatch('logout', {navigate: true});
    }
  },
  mounted() {
    this.getPersonProfile();
  }
}
</script>

<style scoped>

</style>