<template>
  <v-card :style="'max-width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12"
               class="d-flex justify-center"
        >
          <img src="images/logo/eagi.png" style="width:100px" alt="">
        </v-col>
        <v-col cols="12"
               class="d-flex justify-center"
        >
          <h2>
            Thank you for visiting #MyNWU
          </h2>
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <p style="max-width: 500px; text-align: center">
            We look forward to seeing you in the new year. If you haven’t applied yet – what are you waiting for?
            <br>
            <br>
            <v-btn @click="routeToApply()" small color="accent">Apply here</v-btn>
          </p>

        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <p style="max-width: 500px; text-align: center">
            Unsure about what to study? Thanks to our InterestIndex you can match possible careers and study courses to your specific interests. Visit
            <a href="https://www.interestindex.co.za/" target="_blank">https://www.interestindex.co.za/</a> to explore your future career path.
            <br>
            <br>
            <v-btn @click="routeToInterest()" small color="accent">Get My Interests</v-btn>
          </p>

        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <p style="max-width: 500px; text-align: center">
            Or do you have a question? Telegram <a href="http://telegram.me/MyNWUBot">@MyNWUBot</a> or WhatsApp us at 060 070 2606 or send an email to studies@mynwu.info
          </p>

        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <p style="max-width: 500px; text-align: center">
            <v-btn @click="back()" color="primary">Another tour</v-btn>
            <br>
            <br>
            <v-btn small @click="logout()" color="secondary">Exit</v-btn>
          </p>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
  methods: {
    routeToApply(){
      window.open('http://studies.nwu.ac.za/studies/how-apply-online','_blank');
    },
    routeToInterest(){
      window.open('https://www.interestindex.co.za/','_blank');
    },
    repeat(){
      this.$router.replace({name:'Video'})
    },
    back(){
      this.$router.replace({name:'InterestsAndCourseGroups'})
    },
    logout(){
      this.$store.dispatch('logout',{navigate:true});
    }
  }
}
</script>
