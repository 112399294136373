<template>
  <v-img height="100vh"
         width="100%"
         class="pa-0 ma-0"
         :src="'images/background/' + imageNumber + '.png'"
  >
    <v-row justify="center"
           class="fill-height"
           style="overflow-y: auto"
    >
      <v-col cols="xs6" align-self="center">
        <v-row>
          <!-- TITLe -->
          <v-col cols="12"
                 class="d-flex justify-center pb-0"
          >
            <h1 style="color: white;">
              NWU Virtual Tour
            </h1>
          </v-col>

          <!-- CARD -->
          <v-col cols="12"
                 class="d-flex justify-center pt-0"
          >
            <slot name="innerCard">
              No Data
            </slot>
          </v-col>

          <!-- LOGO -->
          <v-col cols="12"
                 class="d-flex justify-center"
          >
            <img src="images/logo/nwu_acronym_logo_white.svg"
                 width="100px"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
export default {
  name: "AppFramework",
  data: () => ({
    imageNumber: 1,
  }),
  mounted() {
    this.imageNumber = Math.floor(Math.random() * 4) + 1;
  }
}
</script>

<style scoped>

</style>