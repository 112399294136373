<template>
  <v-card :style="'width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12"
                   class="pb-0"
            >
              <h2>
                Choose your #MyNWU tour
              </h2>
              <p>We want you to enjoy your tour with no irrelevant information or short lefts that will leave you
                feeling frustrated. So, you can build your personalised NWU experience by selecting from the
                following:</p>
            </v-col>
          </v-row>

          <!-- TITLE -->
          <v-row>
            <v-col cols="12"
                   class="pt-0"
            >
              <h3>
                The campus you want to visit.
              </h3>
            </v-col>
          </v-row>

          <v-radio-group v-model="personData.campusid">
            <v-row>
              <v-col cols="12" sm="4">
                <v-radio label="Mahikeng Campus" :value="3"></v-radio>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio label="Potchefstroom Campus" :value="1"></v-radio>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio label="Vaal Campus" :value="2"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <!-- TITLE
          <v-row>
            <v-col cols="12">
              <h3>
                Interests
              </h3>
            </v-col>
          </v-row>

          <v-row style="max-height: 360px; overflow-y: auto">
            <v-col cols="3"
                   v-for="(interest, interestIndex) in data.interests"
                   :key="'interest_' + interestIndex"
            >
              <v-checkbox v-model="interest.selected">
                <template v-slot:label>
                  <div :style="interest.selected ? 'color: ' + $vuetify.theme.themes.dark.primary : ''">
                    {{ interest.name }}
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>-->

          <!-- Course Groups -->
          <!-- TITLE -->
          <v-row>
            <v-col cols="12">
              <h3>
                What do you want to study?
              </h3>
              <p>Select the course interest groups that you want to explore. Note that the available fields may differ
                slightly based on your campus of choice.</p>
              <v-autocomplete :items="Object.values(coursegroups[personData.campusid])"
                              item-value="coursegroupid"
                              item-text="coursegroupname"
                              v-model="personData.coursegroupid"
                              placeholder="Please select your field"
                              :multiple="true"
              >

              </v-autocomplete>
            </v-col>
          </v-row>

          <!--

          <v-row style="max-height: 360px; overflow-y: auto">
            <v-col cols="3"
                   v-for="(courseGroup, courseGroupIndex) in data.coursegroups"
                   :key="'course_group_' + courseGroupIndex"
            >
              <v-checkbox v-model="courseGroup.selected"
              >
                <template v-slot:label>
                  <div :style="courseGroup.selected ? 'color: ' + $vuetify.theme.themes.dark.primary : ''">
                    {{ courseGroup.name }}
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          -->

          <!-- TITLE -->
          <v-row>
            <v-col cols="12">
              <h3>
                Where are you planning to stay while you study?
              </h3>
            </v-col>
          </v-row>
          <v-radio-group v-model="personData.residence">
            <v-row>
              <v-col cols="12" sm="4">
                <v-radio label="Campus Residence" value="campus"></v-radio>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio label="Private accommodation" value="private"></v-radio>
              </v-col>
              <v-col cols="12" sm="4" v-show="personData.campusid!=3">
                <v-radio label="Town residence" value="town"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <!-- TITLE -->
          <v-row>
            <v-col cols="12">
              <h3>
                Any other interests?
              </h3>
            </v-col>
            <v-col cols="6">
              <v-checkbox :dense="true" label="Sport" v-model="personData.interests.sport"></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox :dense="true" label="Arts and Culture" v-model="personData.interests.culture"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>


        <!-- BUTTONS -->
        <v-col cols class="d-flex flex-row">
          <v-btn color="secondary"
                 :disabled="loading"
                 to="/profile"
          >
            back
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="primary"
                 @click="createVideoTour()"
                 :loading="loading"
                 :disabled="!isValid"
          >
            Show my Tour
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InterestsAndCourseGroups",
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
  data() {
    return {
      personData: {
        campusid: 3,
        residence: 'campus',
        coursegroupid: null,
        interests: {
          sport: true,
          culture: true
        },
      }
    }
  },
  computed: {
    isValid() {
      const d = this.personData;
      return d.campusid && d.residence && d.coursegroupid
    },
    coursegroups() {
      return this.$store.state.coursegroups;
    }
  },
  methods: {
    createVideoTour() {
      this.$store.dispatch('createVideoTour', {data: this.personData}).then(() => {
        this.$router.push({name: 'Video'});
      });
    }
  },
  mounted() {
    if (this.$store.state.videoTour) {
      this.personData.coursegroupid = this.$store.state.videoTour.data.coursegroupid;
      this.personData.interests = this.$store.state.videoTour.data.interests;
    }

    this.$watch('personData', function (data) {
      if (data.campusid == 3 && data.residence == 'town') {
        data.residence = 'campus'
      }
    }, {deep: true});
  }
}
</script>

<style scoped>

</style>