<template>
  <v-card :style="'width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <!-- TITLE -->
        <v-col cols="12">
          LOGIN
        </v-col>

        <!-- BODY -->
        <v-col cols="12">
          <v-tabs v-model="tab"
          >
            <!-- MOBILE -->
            <v-tab>Mobile</v-tab>
            <v-tab-item class="mt-3" eager>
              <p>
                Please enter your mobile phone number to continue.
              </p>
              <v-form ref="loginMobileForm">
                <v-text-field label="Mobile"
                              type="tel"
                              ref="telField"
                              autofocus
                              outlined
                              v-model="mobile"
                              :rules="[
                          () => validMobile || 'The mobile number is invalid.',
                          (v) => !!v || 'Required'
                          ]"
                              @keypress="keyPressed($event)"
                ></v-text-field>
              </v-form>
            </v-tab-item>

            <!-- EMAIL -->
            <v-tab>Email</v-tab>
            <v-tab-item class="mt-3" eager>
              <p>
                Please enter your email to continue.
              </p>
              <v-form ref="loginEmailForm">
                <v-text-field label="Email"
                              type="email"
                              ref="emailField"
                              outlined
                              v-model="email"
                              :rules="[
                          () => validEmail || 'The email is invalid.'
                          ]"
                              @keypress="keyPressed($event)"
                ></v-text-field>
              </v-form>
            </v-tab-item>
          </v-tabs>

        </v-col>

        <!-- BUTTONS -->
        <v-col cols="12"
               class="d-flex flex-row"
        >
          <!-- BACK -->
          <v-btn to="/"
                 color="secondary"
                 :disabled="loading"
          >
            BACK
          </v-btn>

          <v-spacer></v-spacer>

          <!-- REQUEST OTP -->
          <v-btn color="primary"
                 :loading="loading"
                 :disabled="!valid"
                 @click="submit()"
          >
            Request OTP
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Login',
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
  computed: {
    validMobile() {
      let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

      return regex.test(this.mobile);
    },
    validEmail() {
      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return regex.test(this.email);
    },
    valid() {
      if (this.tab === 0) {
        return this.validMobile;
      } else if (this.tab === 1) {
        return this.validEmail;
      }
    }
  },
  watch: {
    tab() {
      this.resetValidation();
    }
  },
  data() {
    return {
      mobile: null,
      email: null,
      tab: 0,
    }
  },
  mounted() {
    this.$watch('tab',(t)=>{
       if(t===0){
         setTimeout(()=>{
           this.$refs.telField.focus();
         },300);
       }else if(t===1){
         setTimeout(()=>{
           this.$refs.emailField.focus();
         },300);
       }
    });
  },
  methods: {
    keyPressed(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    submit() {
      if (this.tab === 0) {
        this.submitMobile();
      } else {
        this.submitEmail()
      }
    },
    submitMobile() {
      if (this.validMobile) {
        this.$store.dispatch('submitMobileForLogin', this.mobile).then(() => {
          this.$router.push({name: 'OTP'})
        });
      }
    },
    submitEmail() {
      if (this.validEmail && this.email) {
        this.$store.dispatch('submitEmailForLogin', this.email).then(() => {
          this.$router.push({name: 'OTP'})
        });
      }
    },
    resetValidation() {
      this.email = null;
      this.mobile = null;

      if (this.tab === 0) {
        this.$refs['loginMobileForm'].resetValidation();
      } else if (this.tab === 1) {
        this.$refs['loginEmailForm'].resetValidation();
      }
    }
  }
}
</script>
