<template>
  <v-card :style="'max-width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12"
               class="d-flex justify-center"
        >
          <h2>
            Welcome to the North-West University!
          </h2>
        </v-col>
        <v-col cols="12"
               style="text-align: center"
        >
          <p>
            #MyNWU is more than a place where you can get a degree, it’s a place where you can explore new opportunities, forge lasting friendships, and make lifelong memories. Explore the university where you can become greater than you ever thought possible.
          </p>
        </v-col>
        <v-col cols="12"
               class="d-flex justify-center"
        >
          <v-btn to="/login"
                 color="primary"
          >
            Start my tour
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
}
</script>
