<template>
  <v-card :style="'width:' + width"
          class="ma-2"
  >
    <v-card-text>
      <v-row>
        <!-- TITLE -->
        <v-col cols="12">
          OTP
        </v-col>

        <!-- BODY -->
        <v-col cols="12">
          <p>A one-time-pin (OTP) has been sent to your {{sentDesc}}. Please enter it
            below.</p>
          <v-alert type="error" v-if="error">{{ error }}</v-alert>
          <v-form ref="loginMobileForm">
            <v-text-field label="OTP"
                          type="tel"
                          autofocus
                          outlined
                          v-model="otp"
                          :rules="[(v) => !!v || 'Required']"
                          @keypress="keyPressed($event)"
            ></v-text-field>
          </v-form>
        </v-col>

        <!-- BUTTONS -->
        <v-col cols="12"
               class="d-flex flex-row"
        >
          <!-- BACK -->
          <v-btn to="/"
                 color="secondary"
                 :disabled="loading"
          >
            BACK
          </v-btn>

          <v-spacer></v-spacer>

          <!-- LOGIN  to="/profile" -->
          <v-btn color="primary"
                 :loading="loading"
                 :disabled="!otp"
                 @click="submitOTP()"

          >
            LOGIN
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OTP',
  props: {
    loading: {
      default: false
    },
    width: {
      default: '500px'
    }
  },
  data() {
    return {
      otp: null,
      error: null
    }
  },
  computed: {
    sentDesc(){
      if(this.$store.state.data.mobile){
        return 'mobile number (' + this.$store.state.data.mobile + ')';
      }

      if(this.$store.state.data.email){
        return 'email (' + this.$store.state.data.email + ')';
      }

      return 'mobile or email'
    }
  },
  methods: {
    keyPressed(e) {
      if (e.keyCode === 13) {
        this.submitOTP();
      }
    },
    submitOTP() {
      if (!this.otp) return;

      let payload = {
        mobile: this.$store.state.data.mobile,
        email: this.$store.state.data.email,
        otp: this.otp
      };
      this.error = null;
      this.$store.dispatch('submitOtpForLogin', payload).then((r) => {
        this.$router.push({name: 'Profile'})
      }).catch((msg) => {
        this.error = msg;
      });
    }
  }
}
</script>
