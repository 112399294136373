<template>
  <div style="width: 100%">
    <v-row no-gutters>
      <v-col cols="12"
             class="d-flex justify-center"
      >
        <img src="images/logo/eagi.png" style="width:20vw; max-width: 100px" alt="">
      </v-col>
      <v-col cols="12"
             class="d-flex justify-center"
      >
        <div class="text-overline">
          {{ progressLabel }}
        </div>
      </v-col>
      <v-col cols="12"
             class="d-flex justify-center"
      >
        <h3 class="primary-text">
          {{ title }}
        </h3>
      </v-col>
      <v-col cols="12"
             class="d-none d-sm-flex"
      >
        <v-stepper style="background-color: transparent;"
                   width="100%"
                   flat
                   tile
                   alt-labels
        >
          <v-stepper-header>
            <template v-for="(node, nodeIndex) in nodes"
            >
              <v-stepper-step :complete="current === nodeIndex || current > nodeIndex"
                              complete-icon=""
                              step=""
              >
                <div style="text-align: center; max-width: 100px"
                     :class="nodes.length > 11 ? 'd-none d-md-flex d-lg-none' : ''"
                >
                  {{ node.short_title }}
                </div>
              </v-stepper-step>

              <v-divider v-if="(nodeIndex + 1) < nodes.length"
                         :color="current > nodeIndex ? dividerColor : 'black'"
                         style="height: 10px"
              ></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
      </v-col>
      <v-col cols="12"
             class="d-flex justify-center mt-1"
      >
        <slot name="button">
          <v-btn color="grey"
                 @click="back()"
                 text
                 small
          >
            New Tour
          </v-btn>
          <v-btn color="primary"
                 @click="buttonClicked()"
                 small
          >
            Continue
          </v-btn>

        </slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VideoTourStatusBar",
  props: {
    title: {},
    nodes: {},
    current: {},
    progress: {}
  },
  computed: {
    progressLabel() {
      if (this.current == 0 && this.progress < 0.1) {
        return "First up";
      }

      return this.progress < 0.1 ? 'Next up' : 'Currently playing';
    }
  },
  data() {
    return {
      step: 1,
      stepperPosition: 1,
      dividerColor: this.$vuetify.theme.themes.light.primary
    }
  },
  methods: {
    buttonClicked() {
      this.$emit('continue');
    },
    back() {
      this.$emit('back');
    }
  }
}
</script>

<style scoped>
.primary-text {
  color: #6F0579 !important;
}
</style>