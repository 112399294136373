import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from "../views/Welcome";
import Login from "../views/Login";
import Profile from "../views/Profile";
import Video from "../views/Video";
import OTP from "../views/OTP";
import store from '../store';
import InterestsAndCourseGroups from "../views/InterestsAndCourseGroups";
import Complete from "../views/Complete";

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
  const isAuthenticated=!!store.state.jwt;
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' });
  else next()
};

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OTP
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter : authGuard
  },
  {
    path: '/interestsandcoursegroups',
    name: 'InterestsAndCourseGroups',
    component: InterestsAndCourseGroups,
    beforeEnter : authGuard
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
    beforeEnter : authGuard
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete,
    beforeEnter : authGuard
  },
  {
    path: '/video2',
    name: 'Video',
    component: Video,
    //beforeEnter : authGuard
  }
]

const router = new VueRouter({
  routes
})

export default router
