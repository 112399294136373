import Vue from 'vue'
import Vuex from 'vuex'
import {VuexPersistence} from "vuex-persist";
import router from '../router'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'nwu-video-tour'
});

export default new Vuex.Store({
  state: {
    person: null,
    data: null,
    videoTour: null,
    jwt: null,
    loading: false,
    coursegroups: {}
  },
  mutations: {
    setPerson(state, person) {
      state.person = person;
    },
    setData(state, data) {
      state.data = data;
    },
    setVideoTour(state, videoTour) {
      state.videoTour = videoTour;
    },
    setCoursegroups(state, coursegroups) {
      state.coursegroups = coursegroups;
    },
    setToken(state, jwt) {
      state.jwt = jwt;
    },
    setGlobalLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    submitMobileForLogin({dispatch, commit}, mobile) {
      commit('setGlobalLoading', true);
      return this.$http.post('/mobile', {mobile}).then((result) => {
        let data = {
          personFound: result.data.personFound,
          mobile: result.data.mobile,
        }

        commit('setData', data);

        commit('setGlobalLoading', false);
      }).catch(() => {
        commit('setGlobalLoading', false);
      });
    },
    submitEmailForLogin({dispatch, commit}, email) {
      commit('setGlobalLoading', true);
      return this.$http.post('/email', {email}).then((result) => {
        let data = {
          personFound: result.data.personFound,
          email: result.data.email,
        }

        commit('setData', data);

        commit('setGlobalLoading', false);
      }).catch(() => {
        commit('setGlobalLoading', false);
      });
    },
    logout({commit}, {navigate}) {
      commit('setToken', null);
      commit('setPerson', null);
      commit('setData', null);
      commit('setGlobalLoading', false);
      if (navigate) {
        router.replace({name: 'Login'});
      }
    },
    submitOtpForLogin({dispatch, commit}, {mobile, email, otp}) {
      commit('setGlobalLoading', true);
      return this.$http.post('/otp', {mobile, email, otp}).then((result) => {
        commit('setGlobalLoading', false);
        if (!result.data.error) {
          commit('setToken', result.data.token);
        } else {
          throw new Error(result.data.error);
        }
        return true;
      }).catch((e) => {
        commit('setGlobalLoading', false);
        throw new Error(e);
      });
    },
    getPersonDetails({commit}) {
      return this.$http.post('/profile', {}).then((result) => {
        commit('setPerson', result.data.person);
        commit('setData', result.data.data);
        commit('setVideoTour', result.data.videotour);
        commit('setCoursegroups', result.data.coursegroups);

        commit('setGlobalLoading', false);
      }).catch(() => {
        commit('setGlobalLoading', false);
      });
    },
    updatePersonDetails({dispatch, commit}, {person}) {
      commit('setGlobalLoading', true);
      return this.$http.post('/update', {person}).then((result) => {
        commit('setPerson', result.data.person);

        commit('setGlobalLoading', false);
      }).catch(() => {
        commit('setGlobalLoading', false);
      });
    },
    createVideoTour({dispatch, commit}, {data}) {
      commit('setGlobalLoading', true);
      return this.$http.post('/videotour', {data}).then((result) => {
        commit('setGlobalLoading', false);
        commit('setVideoTour', result.data.videotour);
      }).catch(() => {
        commit('setGlobalLoading', false);
      });
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})
