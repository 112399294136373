<template>
  <div style="width: 100%; background-color: rgba(255,255,255,0.7); height:100%">
    <v-row no-gutters>
      <v-col cols="12"
             class="d-flex justify-center"
      >
        <img src="images/logo/eagi.png" style="width:100px" alt="">
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <h3 class="primary-text">Thank you for visiting #MyNWU</h3>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p style="max-width: 500px; text-align: center">
          We look forward to seeing you in the new year. If you haven’t applied yet – what are you waiting for? Apply here: <br><a
            href="http://studies.nwu.ac.za/studies/how-apply-online" target="_blank">http://studies.nwu.ac.za/studies/how-apply-online</a>
        </p>

      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p style="max-width: 500px; text-align: center">
          Unsure about what to study? Thanks to our InterestIndex you can match possible careers and study courses to your specific interests. Visit
          <a href="https://www.interestindex.co.za/" target="_blank">https://www.interestindex.co.za/</a> to explore your future career path.
        </p>

      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p style="max-width: 500px; text-align: center">
          Or do you have a question? Telegram <a href="http://telegram.me/MyNWUBot">@MyNWUBot</a> or WhatsApp us at 060 070 2606 or send an email to studies@mynwu.info
        </p>

      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p style="max-width: 500px; text-align: center">
         <v-btn @click="back()" color="primary">Another tour</v-btn>
          <br>
          <br>
         <v-btn small @click="logout()" color="secondary">Exit</v-btn>
        </p>

      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "CompleteOverlay",
    methods:{
      back(){
        this.$router.replace({name:'InterestsAndCourseGroups'})
      },
      logout(){
        this.$store.dispatch('logout',{navigate:true});
      }
    }
  }
</script>

<style scoped>
  .primary-text{
    color:#6F0579!important;
  }
</style>