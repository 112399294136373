<template>
  <div>
    <div style="position:relative">
      <div v-if="!isInTransit && !isPlaying && allLoaded && meta.length>=currentIndex" class="overlay"
           style="background-size: cover"
           :style="{backgroundImage: (coverImages.hasOwnProperty(currentIndex) ? 'url(' + coverImages[currentIndex] + ')' : '')}"
           @click="!isComplete? toggle() : null"
      >
        <div
            class="overlay dark"
        >
          <!-- Commented out for the moment -->
          <complete-overlay v-if="isComplete && false"
          >
          </complete-overlay>
          <video-tour-status-bar :title="meta[currentIndex].title"
                                 :progress="(meta[currentIndex].progress / meta[currentIndex].duration)"
                                 :nodes="meta"
                                 :current="currentIndex"
                                 @continue="play()"
                                 @back="back()"
                                 v-else
          ></video-tour-status-bar>
        </div>
      </div>
      <div v-if="!isPlaying && (!allLoaded || isPreloading)" class="overlay dark"
           @click="toggle()"
      >
        <v-progress-circular :indeterminate="true" color="white"></v-progress-circular>
      </div>
      <div v-else-if="isPlaying" class="overlay"
           @click="toggle()"
      >
      </div>
      <wrapped-div v-for="(video,key) of videos" :key="video.url"
                   v-show="key===currentIndex"
                   :id="'video_'+key"
                   @loaded="createPlayer(video,key,'video_'+key)"
                   @click="toggle()"
                   style="background-color:black"

      >
      </wrapped-div>
    </div>


    <div style="position:relative;width:100%; height:35px">
      <div v-for="(m,key) in meta" :key="key" :style="{width: (m.perc * 100)+'%'}"
           style="background-color:white; float:left; position: relative">

        <v-progress-linear :value="100*m.progress/m.duration" @change="progressClicked(key,$event)"
                           height="8"></v-progress-linear>
        <div style="width:2px; height:17px; background-color: grey;position: absolute;left:0;top:8px; z-index:99"></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                style="height:25px; position: absolute;left:2px;top:8px; z-index:100; width:99%; text-align:center; text-overflow: ellipsis; overflow: hidden;white-space: nowrap; padding: 0 4px 0 4px"
                class="text-caption">
              {{ m.short_title }}
            </div>
          </template>
          <span>{{ m.title }}</span>
        </v-tooltip>

      </div>
    </div>
    <div style="text-align:center">
      <!-- Align Completely left -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="back()"
                 v-bind="attrs"
                 v-on="on"
                 icon
                 style="float: left"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
        </template>
        <span>Change my profile</span>
      </v-tooltip>

      <!-- Align Completely centre -->
      <v-btn @click="previous()" :disabled="isPreloading" icon>
        <v-icon>mdi-skip-previous-outline</v-icon>
      </v-btn>
      <v-btn @click="toggle()" :disabled="isPreloading" icon>
        <v-icon v-if="!isPlaying">mdi-play</v-icon>
        <v-icon v-else>mdi-pause</v-icon>
      </v-btn>
      <v-btn @click="stop()" :disabled="isPreloading" icon>
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn @click="next()" :disabled="isPreloading" icon>
        <v-icon>mdi-skip-next-outline</v-icon>
      </v-btn>

      <!-- Align Completely right -->
      <v-btn @click="enterFullscreen()"
             :disabled="isPreloading"
             icon
             style="float:right"
      >
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';
import WrappedDiv from "./WrappedDiv";
import VideoTourStatusBar from "./VideoTourStatusBar";
import CompleteOverlay from "./CompleteOverlay";

export default {
  name: "MultiVimeoPlayer",
  props: {
    videos: {
      required: true,
      type: Array
    },
    preloadAll: {
      default: true
    }
  },
  components: {
    VideoTourStatusBar,
    CompleteOverlay,
    WrappedDiv
  },
  computed: {
    currentVideoPlayer() {
      if (this.videoPlayers.length === 0 || this.videoPlayers.length - 1 < this.currentIndex || this.currentIndex < 0) {
        return null;
      }
      return this.videoPlayers[this.currentIndex];
    }
  },
  data() {
    return {
      currentIndex: 0,
      videoPlayers: [],
      totalDuration: 0,
      meta: [],
      isPlaying: false,
      isPreloading: true,
      isFullScreen: false,
      wasFullScreen: false,
      allLoaded: false,
      isInTransit: false,
      coverImages: {},
      isComplete: false
    }
  },
  mounted() {

  },
  methods: {
    getCoverImage(videoId) {
      return fetch('https://vimeo.com/api/v2/video/' + videoId + '.json').then((r) => r.json()).then((data) => data[0].thumbnail_large);
    },
    previous() {
      this.isInTransit = true;
      this.meta[this.currentIndex].progress = 0;
      this.videoPlayers[this.currentIndex].pause();
      this.videoPlayers[this.currentIndex].setCurrentTime(0);
      if (this.currentIndex > 0) {
        this.meta[this.currentIndex - 1].progress = 0;
        this.videoPlayers[this.currentIndex - 1].pause();
        this.videoPlayers[this.currentIndex - 1].setCurrentTime(0);
        this.currentIndex--;
      }
      this.play();
    },
    next() {
      this.isInTransit = true;
      this.meta[this.currentIndex].progress = this.meta[this.currentIndex].duration;
      this.videoPlayers[this.currentIndex].pause();
      this.videoPlayers[this.currentIndex].setCurrentTime(this.meta[this.currentIndex].duration - 0.3);
      this.playerEnded(this.currentIndex);
    },
    back() {
      this.$emit('back');
    },
    createPlayer(video, key, id) {
      const url = video.url;
      this.$nextTick(() => {
        const options = {
          url,
          byline: false,
          title: false,
          portrait: false,
          responsive: true,
          controls: false
        };

        const videoPlayer = new Player(id, options);
        videoPlayer.getVideoId().then((id) => {
          this.getCoverImage(id).then((url) => {
            this.coverImages[key] = url;
          });
        });
        videoPlayer.setVolume(1);
        videoPlayer.on('timeupdate', (d) => {
          this.$set(this.meta, key, {...this.meta[key], 'progress': d.seconds});
          this.videoTimeUpdated(key, this.meta[key]);
        });
        videoPlayer.on('progress', (d) => {
          this.$set(this.meta, key, {...this.meta[key], 'buffer': d.seconds});
        });
        videoPlayer.on('ended', (data) => {
          this.playerEnded(key, data);
        });
        videoPlayer.on('pause', (data) => {
          this.playerPaused(key, data);
        });
        videoPlayer.on('play', (data) => {
          this.playerPlayed(key, data);
        });
        videoPlayer.on('fullscreenchange', (data) => {
          this.isFullScreen = data.fullscreen;
          this.wasFullScreen = data.fullscreen;
        });
        if (key === 0) {
          videoPlayer.on('loaded', (data) => {
            this.initialPreloadComplete(data);
          });
        }
        this.videoPlayers.push(videoPlayer);
        if (this.preloadAll) {
          this.preloadPlayer(key);
        }
        if (this.videoPlayers.length === this.videos.length) {
          this.allPlayersLoaded();
        }
      });
    },
    async allPlayersLoaded() {
      const sumReducer = (accumulator, currentValue) => accumulator + currentValue;

      const result = await Promise.all(this.videoPlayers.map(function (player) {
        return player.getDuration();
      })).then((result) => {
        this.totalDuration = result.reduce(sumReducer);
        return result;
      });
      let key = 0;
      for (let duration of result) {
        this.meta[key] = {
          title: this.videos[key].title,
          short_title: this.videos[key].short_title,
          key,
          duration,
          perc: duration / this.totalDuration,
          progress: 0,
          buffer: 0,
        };
        key++;
      }
      this.$forceUpdate();
      this.allLoaded = true;
      //this.play();
    },
    initialPreloadComplete(data) {
      this.isPreloading = false;
    },
    preloadPlayer(key) {
      if (key > this.videoPlayers.length - 1) {
        return;
      }
      const videoPlayer = this.videoPlayers[key];
      videoPlayer.play().then(() => {
        videoPlayer.pause();
      });
    },
    destroyPlayers() {
      for (let player of this.videoPlayers) {
        player.destroy();
      }
    },
    playerEnded(key) {
      if (key >= this.videoPlayers.length - 1) {
        this.videoEnded();
        return;
      }
      this.isInTransit = true;
      this.currentIndex++;
      this.play();
      if (this.isFullScreen) {
        this.currentVideoPlayer.requestFullscreen().catch(() => {
          this.wasFullScreen = true;
          this.pause();
        });
      }
    },
    videoTimeUpdated() {

    },
    progressClicked(key, perc) {
      for (let i = 0; i < this.meta.length; i++) {
        if (i < key) {
          this.meta[i].progress = this.meta[i].duration;
          this.videoPlayers[i].pause();
          this.videoPlayers[i].setCurrentTime(this.meta[i].duration - 0.3);
        } else if (i > key) {
          this.videoPlayers[i].pause();
          this.meta[i].progress = 0;
          this.videoPlayers[i].setCurrentTime(0);
        }
      }
      this.currentIndex = key;
      this.currentVideoPlayer.setCurrentTime(this.meta[key].duration * perc / 100 - 0.1);
      this.currentVideoPlayer.play(); //TODO: if was playing
      this.isComplete = false;
      this.$forceUpdate();
    },
    toggle() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },
    setVolume(volume) {
      for (let player of this.videoPlayers) {
        player.setVolume(volume);
      }
    },
    play() {
      this.isComplete = false;
      if (!this.currentVideoPlayer) return new Promise(() => null);
      return this.currentVideoPlayer.play().then((p) => {
        this.isPlaying = true;
        this.isInTransit = false;
        if (this.wasFullScreen) {
          this.currentVideoPlayer.requestFullscreen();
        }
        return p;
      }).catch(() => {
        this.pause();
      });
    },
    pause() {
      if (!this.currentVideoPlayer) return new Promise(() => null);
      this.isComplete = false;
      return this.currentVideoPlayer.pause().then((p) => {
        this.isPlaying = false;
        this.isInTransit = false;
        return p;
      });
    },
    stop(toIndex = 0) {
      this.isComplete = false;
      if (!this.currentVideoPlayer) return new Promise(() => null);
      return this.currentVideoPlayer.pause().then(() => {
        this.currentIndex = toIndex;
        this.isPlaying = false;
        this.isInTransit = false;
        return this.resetAllPlayers();
      });
    },
    playerPaused(key, data) {
      if (this.currentIndex === key) {
        this.isPlaying = false;
      }
    },
    playerPlayed(key, data) {
      if (this.currentIndex === key) {
        this.isPlaying = true;
      }
    },
    resetAllPlayers() {
      const promises = this.videoPlayers.map(function (player) {
        return player.setCurrentTime(0);
      });
      return Promise.all(promises).then((r) => {
        for (let i = 0; i < this.meta.length; i++) {
          this.meta[i].progress = 0;
        }
        return r;
      });
    },
    videoEnded() {
      this.isPlaying = false;
      this.isComplete = true;
      this.$emit('ended');
    },
    enterFullscreen() {
      this.currentVideoPlayer.requestFullscreen().then(() => {

      });
    }
  },
  beforeDestroy() {
    this.destroyPlayers();
  }
}
</script>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay .content {
  color: white;
  text-align: center;
}

.overlay.dark {
  background-color: rgba(255, 255, 255, 0.41);
  backdrop-filter: blur(5px);
}

</style>