import Vue from 'vue'
import axios from "axios";
import Vuex from "vuex";
import store from '../store';

Vue.prototype.$http = axios.create({baseURL:process.env.VUE_APP_API_ENDPOINT});
Vuex.Store.prototype.$http = Vue.prototype.$http;


Vue.prototype.$http.interceptors.request.use(function (config) {
  if(store.state.jwt!==null){
        config.headers['Authorization']='Bearer '+store.state.jwt;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


Vue.prototype.$http.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if(error.response){
    if((error.response.status===401) || (error.response.data.message.indexOf('auth.invalid')>-1 && store.state.jwt)){
      store.dispatch('logout',{navigate:true});
    }else if(error.response.status===422){
      store.dispatch('logout',{navigate:true});
    }
  }else{
    error.response={data:{message:error.message}};
    if(error.response.data.message.indexOf('auth.invalid')>-1 && store.state.jwt){
      store.dispatch('logout',{navigate:true});
    }
  }
  return Promise.reject(error);
});